import React, { useEffect, useState } from 'react'
import playStyles from "../css/play.module.css";

function PostImages({ currentPost }) {
    var timerRef = null;
    const imagesUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/uploads/";
    const images = currentPost["images"].split("|");
    const [currentActive, setCurrentActive] = useState(-1);

    useEffect(() => {
        setCurrentActive(0);
    }, []);

    useEffect(() => {
        if (images.length === 0) return;
        clearTimeout(timerRef);
        const nextIndex = (currentActive < images.length - 1) ? currentActive + 1 : 0;
        timerRef = setTimeout(() => {
            setCurrentActive(nextIndex);
        }, currentPost["seconds"] / images.length * 1000);

    }, [currentActive]);

    return (
        <>
            <div id="carouselPostshow" className={playStyles.post_images + " carousel post"} data-bs-ride="carousel">
                <div className={playStyles.carousel_inner + " carousel_inner"}>
                    {
                        images.map((image, index) => (
                            <div key={"post" + index} className={index === currentActive ? playStyles.carousel_item + " carousel-item active" : playStyles.carousel_item + " carousel-item"}>
                                <img src={imagesUrl + image} alt="Coop Molle" />
                            </div>
                        ))
                    }
                </div>
                <div className={playStyles.image_title}>
                    <h5>{currentPost["title"]}</h5>
                </div>
            </div>

        </>
    )
}

export default PostImages
