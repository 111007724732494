import React, { useEffect, useState } from 'react'
import axios from 'axios';
import postStyles from "../css/post.module.css";


function CategoriesList({ maxheigth }) {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getAllCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllCategories = async () => {
        const categoriesUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/tablas/posts/modelo.php?op=getactivecategories";
        const response = await axios.get(categoriesUrl);
        setCategories(response.data.data);
    }

    return (
        <>
            {/* <!--Categories widget--> */}
            <div className={maxheigth ? "card mb-4 overflow-auto max-height-10 p-0 m-0" : "card mb-4 p-0 m-0"}>
                <div className="card-header">Categorías</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col col-12">
                            <ul className="list-unstyled mb-0">
                                {
                                    categories.map((category) => (
                                        <li key={category["id"]} ><a className={postStyles.category_link} href={"./posts?category=" + category["id"] + "&categoryName=" + category["category"]}>{category["category"]}</a></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoriesList
