import React, { useEffect, useState } from 'react'
import playStyles from "../css/play.module.css";

function PostText({ currentPost }) {

  var timerRef = null;
  var postTextElement;
  const [currentScroll, setCurrentScroll] = useState(-1);
  const [maxScroll, setMaxScroll] = useState(0);


  useEffect(() => {
    postTextElement = document.querySelector("#postText");
    setMaxScroll(postTextElement.scrollHeight - postTextElement.clientHeight);
    postTextElement.addEventListener("scroll", event => {
      setCurrentScroll(postTextElement.scrollTop);
    });
    setCurrentScroll(0);
  }, []);

  useEffect(() => {
    postTextElement = document.querySelector("#postText");
    clearTimeout(timerRef);
    timerRef = setTimeout(() => {
      postTextElement.scrollTop += 1
    }, (currentPost["seconds"]) / maxScroll);
  }, [currentScroll]);

  return (
    <div id="postText" className={playStyles.post_text + ' pt-5 pb-5'}>
      {/* <p className='current-scroll'>{currentScroll + " | " + maxScroll}</p> */}
      <h1 className='text-center mt-5'>{currentPost["title"]}</h1>
      <div className={playStyles.content_text} dangerouslySetInnerHTML={{ __html: currentPost["text"] }} />
    </div>
  )
}

export default PostText
