import React from 'react'
import Header from '../components/Header'
import PostsList from '../components/PostsList'
import "../css/pages.css";

function Posts() {
  return (
    <>
      <Header fixed={false} />
      <div className='posts'>
        <PostsList />
      </div>
    </>
  )
}

export default Posts
