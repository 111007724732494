import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import CategoriesList from './CategoriesList';

function PostsList() {

    const [loading, setLoading] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const [posts, setPosts] = useState([]);
    const [category, setCategory] = useState([]);
    const [categoryName, setCategoryName] = useState([]);

    useEffect(() => {
        getAllPosts();
    }, []);

    const getAllPosts = async () => {
        const searchParam = searchParams.get("search");
        const categoryParam = searchParams.get("category");
        const postsUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/tablas/posts/modelo.php?op=getall&search=" + searchParam + "&category=" + categoryParam;
        const response = await axios.get(postsUrl);
        //Filter categories
        var _categories = [];
        for (let i = 0; i < response.data.data.length; i++) {
            if (!_categories.includes(response.data.data[i]["category"])) {
                _categories.push(response.data.data[i]["category"]);
            }
        }
        setPosts(response.data.data);
        setLoading(false);
    }

    useEffect(() => {
        if (!loading)
            getAllPosts();
        setCategory(searchParams.get("category"))
        setCategoryName(searchParams.get("categoryName"))

    }, [searchParams]);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
    }

    const removeCategoryFilter = () => {
        setSearchParams({ "category": "" });
    }

    return (
        <>
            <div className="post-list p-3">
                {/* Buscador */}
                <div className="row m-0 mt-3 p-0">
                    <div className="col col-12">
                        <form onSubmit={handleSearchSubmit}>
                            <input
                                id="mainSearch"
                                className="form-control mainSearch"
                                type="search"
                                placeholder="BUSCAR"
                                aria-label="Buscar"
                                autoComplete="off"
                                value={searchParams.get("search") ? searchParams.get("search") : ""}
                                onChange={event => setSearchParams({ "search": event.target.value })}
                            />
                        </form>
                    </div>
                </div>
                {
                    category !== "" && category !== null ?
                        (
                            <div className="row m-0 mt-3 p-0">
                                <div className="col col-12">
                                    <span>Filtro actual: </span> <button onClick={() => removeCategoryFilter()} className="btn btn-secondary">{categoryName}  X</button>
                                </div>
                            </div>
                        ) : null
                }
                {/* Listado */}
                <div className="row m-0 mt-3 p-0">
                    <div className="col col-12 col-md-6 col-xl-4">
                        <CategoriesList maxheigth={true} />
                    </div>
                    {
                        posts.map((post, index) => (
                            <div key={"post" + index} className="col col-12 col-md-6 col-xl-4 mb-3">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <p className="card-text text-center">{post["date"]}</p>
                                        <h5 className="card-title text-center">{post["title"]}</h5>
                                        <p className="text-center w-100"><Link to={"../postNew?id=" + post["id"]}><button className="btn btn-primary">ABRIR</button></Link></p>
                                        {post["type"] === "images" ? <p className="card-text text-center">Imágenes</p> : null}
                                        {post["type"] === "text" ? <p className="card-text text-center">Texto</p> : null}
                                        {post["type"] === "video" ? <p className="card-text text-center">Video</p> : null}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default PostsList
