import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import postStyles from "../css/post.module.css";
import { FaRegArrowAltCircleUp } from 'react-icons/fa';
import CategoriesList from './CategoriesList';
import YoutubeComponent from './YoutubeComponent';

import { FaFacebookSquare } from "react-icons/fa";
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

function PostNewComponent({ post }) {
    const navigate = useNavigate();
    const [currentScroll, setCurrentScroll] = useState(-1);
    const [textToSearch, setTextToSearch] = useState("");
    const imagesUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/uploads/";
    const _images = post["images"] !== "" && post["images"] !== null ? post["images"].split("|") : [];
    const mainImage = _images.length > 0 ? _images[0] : "";
    const images = _images.map((image) => (
        {
            original: imagesUrl + "/" + image,
            thumbnail: imagesUrl + "/thumbs/" + image,
            thumbnailClass: "thumbnailGallery"
        }
    ))

    useEffect(() => {
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScroll = () => {
        setCurrentScroll(window.scrollY);
    };

    const searchHandle = () => {
        if (textToSearch !== "")
            navigate("../posts?search=" + textToSearch, { replace: true });
    }

    return (
        <div>
            <Helmet>
                <title>{post["title"]}</title>
                <meta property='og:url' content={"https://www.coopmolleinforma.com.ar/postNew?id=" + post["id"]} />
                <meta property='og:type' content='article' />
                <meta property='og:title' content='Cooperativa de Luz y Fuerza Pozo del Molle Ltda' />
                <meta property='og:description' content={post["title"]} />
                <meta property='og:image' content={mainImage !== "" ? imagesUrl + "/" + mainImage : ""} />
            </Helmet>
            <div>
                {/* <!--Page content--> */}
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-8">
                            {/* <!--Post content--> */}
                            <article>
                                {/* <!--Post header--> */}
                                <header className="mb-4">
                                    {/* <!--Post title--> */}
                                    <h1 className="fw-bolder mb-1">{post["title"]}</h1>
                                    {/* <!--Post meta content--> */}
                                    <div className="text-muted fst-italic mb-2">{post["date"]}</div>
                                    {/* <!--Post categories--> */}
                                    <a className="badge bg-secondary text-decoration-none link-light" href="#!">{post["category"]}</a>
                                </header>
                                {/* <!--Preview image figure--> */}
                                {
                                    mainImage !== "" ?
                                        <figure className="mb-4"><img className="img-fluid rounded" src={imagesUrl + mainImage} alt={post["title"]} /></figure>
                                        : null

                                }
                                {/* <!--Post video--> */}
                                <section className="mb-5">
                                    {
                                        (post["videourl"] !== "" && post["videourl"] !== "#") ?
                                            <YoutubeComponent embedId={post["videourl"]} autoplay={false} responsive={false} />
                                            : null
                                    }
                                </section>
                                {/* <!--Post content--> */}
                                <section className="mb-5">
                                    <div dangerouslySetInnerHTML={{ __html: post["text"] }} />
                                </section>
                                <section className="mb-5">
                                    <div className="col col-12">
                                        {
                                            images.length > 0 ?
                                                <ImageGallery items={images} lazyLoad={true} />
                                                : null
                                        }
                                    </div>
                                </section>
                            </article>
                        </div>
                        {/* <!--Side widgets--> */}
                        <div className="col-lg-4">
                            {/* <!--Search widget--> */}
                            <div className="card mb-4">
                                <div className="card-header">Buscar</div>
                                <div className="card-body">
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            value={textToSearch}
                                            onChange={event => setTextToSearch(event.target.value)}
                                            onKeyPress={event => {
                                                if (event.key === 'Enter') {
                                                    searchHandle();
                                                }
                                            }}
                                            type="text"
                                            placeholder="¿Qué es lo que busca?..."
                                            aria-label="¿Qués lo que busca?..."
                                            aria-describedby="button-search" />
                                        <button onClick={() => searchHandle()} className="btn btn-primary" id="button-search" type="button">Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <CategoriesList />
                        </div>
                    </div>
                </div>

                {/* <!--Facebook--> */}
                <div className={postStyles.compartir_facebook}>
                    <a target='_blank' rel="noreferrer" href={'https://www.facebook.com/sharer.php?u=https://coopmolleinforma.com.ar/postNew?id=' + post["id"]}><FaFacebookSquare /></a>
                </div>

                <FaRegArrowAltCircleUp className={currentScroll > 150 ? postStyles.back_top : 'd-none'} onClick={() => window.scrollTo(0, 0)} title='Volver al inicio' />
                {/* <!--Footer--> */}
                <footer className="py-5 bg-dark">
                    <div className="container">
                        <div className="row">
                            <p className="m-0 mb-3 text-center text-white col col-12 col-xl-9">Cooperativa de luz y fuerza obras y servicios publicos y asistenciales "Pozo del Molle" LTDA</p>
                            <p className="m-0 text-center text-white col col-12 col-xl-3">Copyright &copy; FM Informática</p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default PostNewComponent
