import React from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";
import playStyles from "../css/play.module.css";

const videoOptions = {
    playerVars: {
        autoplay: 0,
        controls: 0,
        rel: 0,
        showinfo: 0,
        loop: 1
    }
}
const videoOptionsAutoplay = {
    playerVars: {
        autoplay: 1,
        controls: 0,
        rel: 0,
        showinfo: 0,
        mute: 1,
        loop: 1
    }
}

const YoutubeComponent = ({ embedId, autoplay, responsive }) => (

    < div className={responsive ? playStyles.video_responsive : "text-center"} >
        {autoplay ?
            <YouTube videoId={embedId} opts={videoOptionsAutoplay} rel={0} /> :
            <YouTube videoId={embedId} opts={videoOptions} rel={0} />
        }
    </div >
);

YoutubeComponent.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeComponent
