import React from 'react'
import YoutubeComponent from './YoutubeComponent'
import playStyles from "../css/play.module.css";

function PostVideo({ currentPost }) {
  return (
    <div className={playStyles.post_video}>
      <YoutubeComponent embedId={currentPost["videourl"]} autoplay={true}  responsive={true} />
    </div>
  )
}

export default PostVideo
