import React from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function ScreensList() {
    const screensUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/tablas/screens/modelo.php?op=getall";
    const [loading, setLoading] = useState(true);
    const [screens, setScreens] = useState([]);

    useEffect(() => {
        getAllScreens();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllScreens = async () => {
        const response = await axios.get(screensUrl);
        setScreens(response.data.data);
        setLoading(false)
    }
    return (
        <>
            <div className="row p-0 m-0 pb-1 mt-4">
                <div className="col col-12">
                    <p className="text-center w-100">
                        <Link to="./posts"><button className='btn btn-primary'>Ver todas las presentaciones</button></Link>
                    </p>
                </div>
            </div>
            <div className="row p-0 m-0 ">
                {
                    screens.map((screen, index) => (
                        <div key={"screen" + index} className="col col-12 col-md-4 p-0 m-0">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title text-center">{screen["name"]}</h5>
                                    <p className="card-text text-center">{screen["enable"] === "1" ? "HABILITADA" : "DESHABILITADA"}</p>
                                    <p className="text-center w-100"><a href={"./play?screen=" + screen["id"]} className="btn btn-primary">ABRIR</a></p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default ScreensList
