import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import "../css/pages.css";
import 'typeface-roboto';
import Header from '../components/Header';
import PostNewComponent from '../components/PostNewComponent';

function PostNew() {

    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [currentIdPost, setCurrentIdPost] = useState(null);
    const [currentPost, setCurrentPost] = useState(null);

    useEffect(() => {
        const _idPost = searchParams.get("id");
        setCurrentIdPost(_idPost);
    }, [searchParams]);


    useEffect(() => {
        if (currentIdPost !== null)
            getPost();
    }, [currentIdPost]);

    const getPost = async () => {
        const postsUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/tablas/posts/modelo.php?op=get&idpost=" + currentIdPost;
        const response = await axios.get(postsUrl);
        const _post = response.data.data.length > 0 ? response.data.data[0] : null;
        setCurrentPost(_post);
        setLoading(false)
    }

    return (
        <div className='post'>
            <Header fixed={false} />
            {
                loading || currentPost === null ? "" :
                    (
                        <PostNewComponent post={currentPost} />
                    )
            }
        </div>
    );
}
export default PostNew