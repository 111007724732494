import React from 'react'
import playStyles from "../css/play.module.css";

function PostTransition() {
    return (
        <div className={playStyles.post_transition}>
            <img src="./img/coopmolle.jpg" alt="Coopmolle" />
        </div>
    )
}

export default PostTransition
