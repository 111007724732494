import {Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './css/App.css';
import './css/scrollBar.css';
import 'typeface-roboto'
import Play from './pages/Play';
import Posts from './pages/Posts';
import Screens from './pages/Screens';
import PostNew from './pages/PostNew';

function App() {
    return (
    <HelmetProvider>
      <div className="App">
        <>
          <Routes>
            <Route path="/" element={<Screens />} />
            <Route path="/play" element={<Play />} />
            <Route path="/posts" element={<Posts />} />
            <Route path="/postnew" element={<PostNew />} />
          </Routes>
        </>
      </div >
    </HelmetProvider>
  );
}

export default App;