import React from 'react'
import { Link } from 'react-router-dom'
import playStyles from "../css/play.module.css";


function Header({ fixed }) {
    return (
        <>
            <Link className={fixed ? playStyles.main_header_fixed : playStyles.main_header + ' pt-1 pe-0 pe-md-5'} to="/">Cooperativa de luz y fuerza obras y servicios publicos y asistenciales "Pozo del Molle" LTDA</Link>
            <Link className={playStyles.logo + ' d-none d-md-block'} to="/"><img src={process.env.REACT_APP_HOME + "/img/logo.png"} alt="Coop Molle informa" /></Link>
        </>
    )
}

export default Header
