import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import playStyles from "../css/play.module.css";
import SlideImages from '../components/SlideImages';
import SlideText from '../components/SlideText';
import SlideVideo from '../components/SlideVideo';
import SlideTransition from '../components/SlideTransition';
import Header from '../components/Header';

function Play() {
    var timerRef = null;
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [idScreen, setIdScreen] = useState(null);
    const [posts, setPosts] = useState([]);
    const [currentPostIndex, setCurrentPostIndex] = useState(null);
    const [currentPost, setCurrentPost] = useState(null);

    useEffect(() => {
        const _idScreen = searchParams.get("screen");
        setIdScreen(_idScreen);
    }, [searchParams]);


    useEffect(() => {
        if (idScreen !== null) {
            getAllPosts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idScreen])

    useEffect(() => {
        if (!loading)
            setCurrentPostIndex(0);
    }, [loading]);

    useEffect(() => {
        if (posts.length === 0 || currentPostIndex === null) return;

        clearTimeout(timerRef);
        setCurrentPost(posts[currentPostIndex])

        const nextIndex = (currentPostIndex < posts.length - 1) ? currentPostIndex + 1 : 0;
        timerRef = setTimeout(() => {
            setCurrentPostIndex(nextIndex);
        }, posts[currentPostIndex]["seconds"] * 1000);

    }, [currentPostIndex]);


    const getAllPosts = async () => {
        const postsUrl = process.env.REACT_APP_BACKEND_URL + "/admin/app/tablas/posts/modelo.php?op=getall&screen=" + idScreen;
        const response = await axios.get(postsUrl);
        const _posts = [];
        for (var i = 0; i < response.data.data.length; i++) {
            _posts.push(response.data.data[i]);
            _posts.push({ id: null, type: "null", seconds: 2 });
        }
        setPosts(_posts);
        setCurrentPostIndex(null);
        setCurrentPost(null);
        setLoading(false)
    }

    return (
        <div className={playStyles.play}>
            <Header fixed={true} />
            {
                <div className={playStyles.sliders_wrapper}>
                    {
                        currentPost && (
                            currentPost["type"] === "null" ?
                                <SlideTransition /> :
                                null
                        )
                    }
                    {
                        currentPost && (
                            currentPost["type"] === "text" ?
                                <SlideText currentPost={currentPost} /> :
                                null
                        )
                    }
                    {
                        currentPost && (
                            currentPost["type"] === "images" ?
                                <SlideImages currentPost={currentPost} visible={currentPost["type"] === "images"} /> :
                                null
                        )
                    }
                    {
                        currentPost && (
                            currentPost["type"] === "video" ?
                                <SlideVideo currentPost={currentPost} visible={currentPost["type"] === "video"} /> :
                                null
                        )
                    }
                </div>
            }
        </div>
    )
}

export default Play
