import React from 'react'
import Header from '../components/Header'
import ScreensList from '../components/ScreensList'
import "../css/pages.css";

function Screens() {
  return (
    <>
      <Header fixed={false} />
      <div className='screens'>
        <ScreensList />
      </div>
    </>
  )
}

export default Screens
